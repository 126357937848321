<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.5rem;">账号</span>
					<el-input v-model="searchinput" placeholder="请输入账号"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="resetsearch">重置</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 73vh;padding-top: 10px;box-sizing: border-box;">
					<MyTable ref="accountref" :tableData="tableData" :tableHeader="tableHeader" @changswitch="changswitch"
							 :showbm="true" :czwidth="190" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showedit(scope.row)" type="primary" size="mini">修改角色权限</el-button>
						</template>
					</MyTable>
				</div>

			</div>
		</div>
		<!--修改角色权限-->
		<el-dialog title="修改角色权限" :visible.sync="setrole" width="30%">
			<el-form ref="addnewinfo" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="当前角色" required>
					<el-select v-model="nowroles" placeholder="请选择" style="width: 80%;">
					    <el-option
					      v-for="item in options"
					      :key="item.id"
					      :label="item.title"
					      :value="item.id">
					    </el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setrole=flase">取 消</el-button>
				<el-button type="primary" @click="changepwd">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { number } from 'echarts'
	export default {
		data() {
			return {
				options: [],
				searchtype: '',
				searchinput: '',
				tableHeader: [
					{
						name: '账号',
						prop: 'username',
						width: '150'
					},
					{
						name: '昵称',
						prop: 'name',
						width: '150'
					},
					{
						name: '所属部门',
						prop: 'organizational_name',
						width: ''
					},
					{
						name: '所属角色',
						prop: 'role_name',
						width: '150',
					}
				],
				tableData: [],
				addnew: false,
				isedite: false,
				setrole: false,
				nowroles:'',
				nowdata:'',
				setpwdid:''
			}
		},
		mounted() {
			this.gettable()
			
			this.$post({
				url:'/api/roleadmin/index',
				params:{
					p:1,
					size:999
				}
			}).then((res)=>{
				this.options = res.list
			})
		},
		methods: {
			goadd(){
				this.$router.push('/system/account_add')
			},
			resetsearch(){
				this.searchinput=''
				this.searchtype=''
				this.$refs.accountref.inputpage = 1
				this.gettable()
			},
			search(){
				this.$refs.accountref.inputpage = 1
				this.gettable()
			},
			gettable(){
				this.$post({
					url:'/api/user/index',
					params:{
						p:this.$refs.accountref.inputpage,
						size:this.$refs.accountref.pagesize,
						name:this.searchinput
					}
				}).then((res)=>{
					this.tableData = res.list
					this.$refs.accountref.total = res.count
				})
			},
			changepwd(){
				if(!this.nowroles){
					this.$message.error('请选择角色')
					return false
				}
				this.nowdata.role_id = this.nowroles
				delete this.nowdata.addtime
				this.$post({
					url:'/api/user/edit',
					params:this.nowdata
				}).then((res)=>{
					this.$message.success('操作成功')
					this.nowroles = ''
					this.nowdata = ''
					this.setrole=false
					this.gettable()
				})
			},
			changswitch(row){
				let baseinfo =JSON.parse(JSON.stringify(row) )
				baseinfo.password = ''
				delete baseinfo.createtime
				delete baseinfo.updatetime
				delete baseinfo.ROW_NUMBER
				delete baseinfo.avatarurl
				this.$post({
					url: '/admin/admin/edit/id/'+baseinfo.id,
					params: baseinfo
				}).then((res) => {
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			showsetrole(row) {
				this.setpwdid = row.id
				this.setrole = true
			},
			showedit(row) {
				this.nowroles = row.role_id
				this.nowdata = row
				this.setrole = true				
			},
			reset(){
				this.addnewinfo={
					username: '',
					password: '',
					role_ids: '',
					status: 1
				}
				this.addnew = false
				this.isedite = false
			},
			deleterow(row) {
				this.$confirm('确认删除该账号吗？')
					.then(_ => {
						this.$post({
							url:'/admin/admin/delete/id/'+ row.id,
							params:{
								id:row.id
							}
						}).then((res)=>{
							this.$message.success('删除成功');
							this.gettable()
							
						})
					})
					.catch(_ => {});
			
			},
			setaddnew() {
				this.$post({
					url:this.isedite?'/api/user/edit_user/id/'+this.addnewinfo.id:'/api/user/add_user',
					params:this.addnewinfo
				}).then((res)=>{
					this.$message.success('添加成功');
					this.reset()
					this.gettable()
					
				})
				
			}
		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		
	}

	::v-deep .el-table td.el-table__cell {
		
	}

	::v-deep .el-table {
		margin-top: 10px;
		
	}

	::v-deep .el-table__fixed {
		
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}
</style>